<div
  class="troi-daterangepicker"
  #pickerContainer
  [ngClass]="{
    ltr: locale.direction === 'ltr',
    rtl: locale.direction === 'rtl',
    shown: isShown || inline,
    hidden: !isShown && !inline,
    inline: inline,
    'troi-daterangepicker__double': !singleDatePicker && showCalInRanges,
    'show-ranges': rangesArray.length
  }"
  [class]="'drops-' + drops + '-' + opens"
>
  <div class="troi-daterangepicker__content">
    <div class="troi-daterangepicker__ranges" *ngIf="rangesArray.length">
      <div class="troi-daterangepicker__ranges--element" *ngFor="let range of rangesArray">
        <troi-btn
          type="button"
          small
          (click)="clickRange($event, range)"
          [disabled]="disableRange(range)"
          [noBackground]="range !== chosenRange"
          [submit]="false"
        >
          {{ range }}
        </troi-btn>
      </div>
    </div>
    <div
      class="troi-daterangepicker__main"
      [ngClass]="{
        noShadow: !rangesArray.length,
        bottomRadius: !rangesArray.length,
        bottomRightRadius: rangesArray.length
      }"
    >
      <div class="troi-daterangepicker__calendars">
        <div class="calendar" [ngClass]="{ left: !singleDatePicker }" *ngIf="showCalInRanges">
          <div class="calendar-table">
            <div class="calendar--table__head">
              <troi-icon-button
                icon="icon-arrow-head-left"
                [disabled]="
                  !(
                    !calendarVariables.left.minDate ||
                    (calendarVariables.left.minDate.isBefore(calendarVariables.left.calendar.firstDay) &&
                      (!this.linkedCalendars || true))
                  )
                "
                (click)="
                  !calendarVariables.left.minDate ||
                  (calendarVariables.left.minDate.isBefore(calendarVariables.left.calendar.firstDay) &&
                    (!this.linkedCalendars || true))
                    ? clickPrev(sideEnum.LEFT)
                    : null
                "
              ></troi-icon-button>
              <span>
                {{ this.locale.monthNames[calendarVariables?.left?.calendar?.[1]?.[1]?.month()] || 'cal broken' }}
                {{ calendarVariables?.left?.calendar?.[1]?.[1]?.format(' YYYY') || 'cal broken' }}
                <troi-icon-button
                  icon="icon-bold-arrow-down"
                  (click)="showQuickPickLeft = !showQuickPickLeft"
                ></troi-icon-button>
                <div
                  class="troi-daterangepicker__quickpick"
                  *ngIf="showQuickPickLeft"
                  (click)="$event.stopPropagation()"
                >
                  <div class="troi-daterangepicker__quickpick--header">
                    <ng-container *ngIf="!showQuickPickLeftYears; else leftYearsTitle">
                      <troi-icon-button
                        icon="icon-arrow-head-left"
                        [disabled]="
                          calendarVariables.left.dropdowns.currentYear === calendarVariables.left.dropdowns.minYear
                        "
                        (click)="
                          yearChanged(
                            {
                              target: {
                                value: calendarVariables.left.dropdowns.currentYear - 1
                              }
                            },
                            sideEnum.LEFT
                          )
                        "
                      ></troi-icon-button>
                      <span
                        >{{ calendarVariables?.left?.calendar?.[1]?.[1]?.format(' YYYY') }}
                        <troi-icon-button
                          icon="icon-bold-arrow-down"
                          (click)="buildInitialYears(sideEnum.LEFT); showQuickPickLeftYears = true"
                          [disabled]="
                            calendarVariables.left.dropdowns.currentYear === calendarVariables.left.dropdowns.maxYear &&
                            calendarVariables.left.dropdowns.currentYear === calendarVariables.left.dropdowns.minYear
                          "
                        ></troi-icon-button
                      ></span>
                      <troi-icon-button
                        icon="icon-arrow-head-right"
                        [disabled]="
                          calendarVariables.left.dropdowns.currentYear === calendarVariables.left.dropdowns.maxYear
                        "
                        (click)="
                          yearChanged(
                            { target: { value: calendarVariables.left.dropdowns.currentYear + 1 } },
                            sideEnum.LEFT
                          )
                        "
                      ></troi-icon-button>
                    </ng-container>
                    <ng-template #leftYearsTitle>
                      <troi-icon-button
                        icon="icon-arrow-head-left"
                        [disabled]="quickPickLeftYears[0] <= calendarVariables.left.dropdowns.minYear"
                        (click)="updateQuickPickYears(sideEnum.LEFT, quickPickLeftYears[0] - 12)"
                      ></troi-icon-button>
                      <span>{{ quickPickLeftYears[0] }} - {{ quickPickLeftYears[quickPickLeftYears.length - 1] }}</span>
                      <troi-icon-button
                        icon="icon-arrow-head-right"
                        [disabled]="
                          quickPickLeftYears[quickPickLeftYears.length - 1] >= calendarVariables.left.dropdowns.maxYear
                        "
                        (click)="
                          updateQuickPickYears(sideEnum.LEFT, quickPickLeftYears[quickPickLeftYears.length - 1] + 1)
                        "
                      ></troi-icon-button>
                    </ng-template>
                  </div>
                  <div class="troi-daterangepicker__quickpick--content">
                    <ng-container *ngIf="!showQuickPickLeftYears; else leftYearsElse">
                      <ng-container *ngFor="let m of calendarVariables.left.dropdowns.monthArrays">
                        <troi-btn
                          (click)="
                            (calendarVariables.left.dropdowns.inMinYear &&
                              m < calendarVariables.left.minDate.month()) ||
                            (calendarVariables.left.dropdowns.inMaxYear && m > calendarVariables.left.maxDate.month())
                              ? null
                              : monthChanged({ target: { value: m } }, sideEnum.LEFT);
                            showQuickPickLeft = false
                          "
                          [disabled]="
                            (calendarVariables.left.dropdowns.inMinYear &&
                              m < calendarVariables.left.minDate.month()) ||
                            (calendarVariables.left.dropdowns.inMaxYear && m > calendarVariables.left.maxDate.month())
                          "
                          [noBackground]="calendarVariables.left.dropdowns.currentMonth !== m"
                          [submit]="false"
                        >
                          {{ moment().month(m).format('MMM') }}
                        </troi-btn>
                      </ng-container>
                    </ng-container>
                    <ng-template #leftYearsElse>
                      <ng-container *ngFor="let y of quickPickLeftYears">
                        <troi-btn
                          (click)="
                            y < calendarVariables.left.dropdowns.minYear || y > calendarVariables.left.dropdowns.maxYear
                              ? null
                              : yearChanged({ target: { value: y } }, sideEnum.LEFT);
                            showQuickPickLeftYears = false
                          "
                          [disabled]="
                            y < calendarVariables.left.dropdowns.minYear || y > calendarVariables.left.dropdowns.maxYear
                          "
                          [noBackground]="calendarVariables.left.dropdowns.currentYear !== y"
                          [submit]="false"
                        >
                          {{ y }}
                        </troi-btn>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
              </span>
              <troi-icon-button
                icon="icon-arrow-head-right"
                *ngIf="singleDatePicker"
                [disabled]="
                  !(
                    (!calendarVariables.left.maxDate ||
                      calendarVariables.left.maxDate.isAfter(calendarVariables.left.calendar.lastDay)) &&
                    (!linkedCalendars || singleDatePicker)
                  )
                "
                (click)="
                  (!calendarVariables.left.maxDate ||
                    calendarVariables.left.maxDate.isAfter(calendarVariables.left.calendar.lastDay)) &&
                  (!linkedCalendars || singleDatePicker)
                    ? clickNext(sideEnum.LEFT)
                    : null
                "
              ></troi-icon-button>
              <span class="isEmpty" *ngIf="!singleDatePicker"></span>
            </div>

            <div class="calendar--table__week-days calendar-row">
              <span class="cell week-day" *ngIf="showWeekNumbers || showISOWeekNumbers">{{
                this.locale.weekLabel
              }}</span>
              <span class="cell" *ngFor="let dayofweek of locale.daysOfWeek">{{ dayofweek }}</span>
            </div>

            <div class="calendar-row isSelectable" *ngFor="let row of calendarVariables.left.calRows">
              <span class="cell week-day" *ngIf="showWeekNumbers || showISOWeekNumbers">
                <ng-container *ngIf="showWeekNumbers; else elseTemplate">
                  {{ calendarVariables.left.calendar[row][0].week() }}
                </ng-container>
                <ng-template #elseTemplate>
                  {{ calendarVariables.left.calendar[row][0].isoWeek() }}
                </ng-template>
              </span>
              <span
                class="cell"
                *ngFor="let col of calendarVariables.left.calCols"
                [class]="calendarVariables.left.classes[row][col]"
                (click)="clickDate($event, sideEnum.LEFT, row, col)"
                (mouseenter)="hoverDate($event, sideEnum.LEFT, row, col)"
              >
                {{ calendarVariables.left.calendar[row][col].date() }}
              </span>
            </div>
          </div>
        </div>
        <div class="calendar right" *ngIf="showCalInRanges && !singleDatePicker">
          <div class="calendar-table">
            <div class="calendar--table__head">
              <span class="isEmpty"></span>
              <span>
                {{ this.locale.monthNames[calendarVariables?.right?.calendar?.[1]?.[1]?.month()] || 'cal broken' }}
                {{ calendarVariables?.right?.calendar?.[1]?.[1]?.format(' YYYY') || 'cal broken' }}
                <troi-icon-button
                  icon="icon-bold-arrow-down"
                  (click)="showQuickPickRight = !showQuickPickRight"
                ></troi-icon-button>
                <div
                  class="troi-daterangepicker__quickpick"
                  *ngIf="showQuickPickRight"
                  (click)="$event.stopPropagation()"
                >
                  <div class="troi-daterangepicker__quickpick--header">
                    <ng-container *ngIf="!showQuickPickRightYears; else rightYearsTitle">
                      <troi-icon-button
                        icon="icon-arrow-head-left"
                        [disabled]="
                          calendarVariables.right.dropdowns.currentYear === calendarVariables.right.dropdowns.minYear
                        "
                        (click)="
                          yearChanged(
                            {
                              target: {
                                value: calendarVariables.right.dropdowns.currentYear - 1
                              }
                            },
                            sideEnum.RIGHT
                          )
                        "
                      ></troi-icon-button>
                      <span
                        >{{ calendarVariables?.right?.calendar?.[1]?.[1]?.format(' YYYY') }}
                        <troi-icon-button
                          icon="icon-bold-arrow-down"
                          (click)="buildInitialYears(sideEnum.RIGHT); showQuickPickRightYears = true"
                          [disabled]="
                            calendarVariables.right.dropdowns.currentYear ===
                              calendarVariables.right.dropdowns.maxYear &&
                            calendarVariables.right.dropdowns.currentYear === calendarVariables.right.dropdowns.minYear
                          "
                        ></troi-icon-button
                      ></span>
                      <troi-icon-button
                        icon="icon-arrow-head-right"
                        [disabled]="
                          calendarVariables.right.dropdowns.currentYear === calendarVariables.right.dropdowns.maxYear
                        "
                        (click)="
                          yearChanged(
                            { target: { value: calendarVariables.right.dropdowns.currentYear + 1 } },
                            sideEnum.RIGHT
                          )
                        "
                      ></troi-icon-button>
                    </ng-container>
                    <ng-template #rightYearsTitle>
                      <troi-icon-button
                        icon="icon-arrow-head-left"
                        [disabled]="quickPickRightYears[0] <= calendarVariables.right.dropdowns.minYear"
                        (click)="updateQuickPickYears(sideEnum.RIGHT, quickPickRightYears[0] - 12)"
                      ></troi-icon-button>
                      <span
                        >{{ quickPickRightYears[0] }} - {{ quickPickRightYears[quickPickRightYears.length - 1] }}</span
                      >
                      <troi-icon-button
                        icon="icon-arrow-head-right"
                        [disabled]="
                          quickPickRightYears[quickPickRightYears.length - 1] >=
                          calendarVariables.right.dropdowns.maxYear
                        "
                        (click)="
                          updateQuickPickYears(sideEnum.RIGHT, quickPickRightYears[quickPickRightYears.length - 1] + 1)
                        "
                      ></troi-icon-button>
                    </ng-template>
                  </div>
                  <div class="troi-daterangepicker__quickpick--content">
                    <ng-container *ngIf="!showQuickPickRightYears; else rightYearsElse">
                      <ng-container *ngFor="let m of calendarVariables.right.dropdowns.monthArrays">
                        <troi-btn
                          (click)="
                            (calendarVariables.right.dropdowns.inMinYear &&
                              m < calendarVariables.right.minDate.month()) ||
                            (calendarVariables.right.dropdowns.inMaxYear && m > calendarVariables.right.maxDate.month())
                              ? null
                              : monthChanged({ target: { value: m } }, sideEnum.RIGHT);
                            showQuickPickRight = false
                          "
                          [disabled]="
                            (calendarVariables.right.dropdowns.inMinYear &&
                              m < calendarVariables.right.minDate.month()) ||
                            (calendarVariables.right.dropdowns.inMaxYear && m > calendarVariables.right.maxDate.month())
                          "
                          [noBackground]="calendarVariables.right.dropdowns.currentMonth !== m"
                          [submit]="false"
                        >
                          {{ moment().month(m).format('MMM') }}
                        </troi-btn>
                      </ng-container>
                    </ng-container>
                    <ng-template #rightYearsElse>
                      <ng-container *ngFor="let y of quickPickRightYears">
                        <troi-btn
                          (click)="
                            y < calendarVariables.right.dropdowns.minYear ||
                            y > calendarVariables.right.dropdowns.maxYear
                              ? null
                              : yearChanged({ target: { value: y } }, sideEnum.RIGHT);
                            showQuickPickRightYears = false
                          "
                          [disabled]="
                            y < calendarVariables.right.dropdowns.minYear ||
                            y > calendarVariables.right.dropdowns.maxYear
                          "
                          [noBackground]="calendarVariables.right.dropdowns.currentYear !== y"
                          [submit]="false"
                        >
                          {{ y }}
                        </troi-btn>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
              </span>
              <troi-icon-button
                icon="icon-arrow-head-right"
                [disabled]="
                  !(
                    !calendarVariables.right.maxDate ||
                    (calendarVariables.right.maxDate.isAfter(calendarVariables.right.calendar.lastDay) &&
                      (!linkedCalendars || singleDatePicker || true))
                  )
                "
                (click)="
                  !calendarVariables.right.maxDate ||
                  (calendarVariables.right.maxDate.isAfter(calendarVariables.right.calendar.lastDay) &&
                    (!linkedCalendars || singleDatePicker || true))
                    ? clickNext(sideEnum.RIGHT)
                    : null
                "
              ></troi-icon-button>
            </div>

            <div class="calendar--table__week-days calendar-row">
              <span class="cell week-day" *ngIf="showWeekNumbers || showISOWeekNumbers">{{
                this.locale.weekLabel
              }}</span>
              <span class="cell" *ngFor="let dayofweek of locale.daysOfWeek">{{ dayofweek }}</span>
            </div>

            <div class="calendar-row isSelectable" *ngFor="let row of calendarVariables.right.calRows">
              <span class="cell week-day" *ngIf="showWeekNumbers || showISOWeekNumbers">
                <ng-container *ngIf="showWeekNumbers; else elseTemplate">
                  {{ calendarVariables.right.calendar[row][0].week() }}
                </ng-container>
                <ng-template #elseTemplate>
                  {{ calendarVariables.right.calendar[row][0].isoWeek() }}
                </ng-template>
              </span>
              <span
                class="cell"
                *ngFor="let col of calendarVariables.right.calCols"
                [class]="calendarVariables.right.classes[row][col]"
                (click)="clickDate($event, sideEnum.RIGHT, row, col)"
                (mouseenter)="hoverDate($event, sideEnum.RIGHT, row, col)"
              >
                {{ calendarVariables.right.calendar[row][col].date() }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="troi-daterangepicker__buttons buttons_input">
        <!-- <troi-icon-button
          *ngIf="showClearButton"
          [attr.data-name]="locale.clearLabel"
          [icon]="'icon-trash-can'"
          (click)="clear()"
        ></troi-icon-button> -->
        <troi-btn
          *ngIf="showClearButton"
          [attr.data-name]="locale.clearLabel"
          [outline]="true"
          [submit]="false"
          (click)="clear()"
        >
          {{ locale.clearLabel }}
        </troi-btn>
        <troi-btn *ngIf="showCancel" [outline]="true" [submit]="false" (click)="clickCancel($event)">
          {{ locale.cancelLabel }}
        </troi-btn>
        <troi-btn [disabled]="applyButton.disabled" [submit]="false" (click)="clickApply($event)">
          {{ locale.applyLabel }}
        </troi-btn>
      </div>
    </div>
  </div>
</div>
